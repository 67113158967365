import React, { useLayoutEffect, useCallback, useState } from "react";

import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";

import { useLoading } from "../../../../shared/hooks/useLoading";

// import { Header } from "../../../../components/page-releated/consulta-previa/header";
import { ILayers } from "../../../../shared/interfaces/ILayers";
import {
    IProperty,
    IPropertyGeom,
} from "../../../../shared/interfaces/IProperties";
import { LayersApiService } from "../../../../shared/services/api/layers-api.service";
import { PropertiesApiService } from "../../../../shared/services/api/properties-api.service";
import { DisclaimerPalmeira } from "../../../../components/page-releated/consulta-previa/disclaimer/palmeira";
import { ZoneamentoPalmeira } from "../../../../components/page-releated/consulta-previa/zoneamento/palmeira";
import { PalmeiraPrincipaisDados } from "../../../../components/page-releated/consulta-previa/palmeira/dados";
import { PalmeiraAlvaras } from "../../../../components/page-releated/consulta-previa/palmeira/alvaras";
import { PalmeiraLeiZoneamento } from "../../../../components/page-releated/consulta-previa/palmeira/lei-zoneamento";
// import { ParkingLot } from "../../../../components/page-releated/consulta-previa/estacionamento";
import { PalmeiraSolo } from "../../../../components/page-releated/consulta-previa/palmeira/solo";
import { PalmeiraGaragem } from "../../../../components/page-releated/consulta-previa/palmeira/garagem";
import { ConsultaPreviaStyle } from "../../../../components/page-releated/consulta-previa/header/styles";
import { HeaderPalmeira } from "../../../../components/page-releated/consulta-previa/palmeira/header";
import { PalmeiraLeiEstacionamento } from "./estacionamento";
// import { PalmeiraAnexo1 } from "../../../../components/page-releated/consulta-previa/palmeira/anexo1";
import { getLayerLegendZoneamentoPalmeira } from "../../../../shared/utils/ol.utils";
// import { showToast } from "../../../../components/global/toast";
import { IWeGeoLegends } from "../../../../shared/interfaces/ILegends";
import { IAlvara } from "../../../../shared/interfaces/IBCI";

export const PalmeiraConsultaPrevia: React.FC = () => {
    const { setLoading } = useLoading();

    const { subscription } = useParams<{
        subscription: string | undefined;
    }>();

    const [property, setProperty] = useState<IProperty>();
    const [propertyGeom, setPropertyGeom] = useState<IPropertyGeom>();
    const [layers, setLayers] = useState<ILayers[]>();
    const [alvara, setAlvara] = useState<IAlvara>();
    // const [legendToastId, setLegendToastId] = useState<Id>();
    const [allLegends, setAlllegends] = useState<IWeGeoLegends[]>();

    const fetchConsultaPrevia = useCallback(async () => {
        document.title = `CONSULTA PRÉVIA - PALMEIRA - ${subscription}`;
        try {
            setLoading(true);

            if (subscription) {
                const {
                    property: propertyResponse,
                    geom: propertyGeomResponse,
                    alvara: alvaraResponse,
                } = await PropertiesApiService.getPropertyConsultaPrevia(
                    subscription
                );
                const layersResponse = await LayersApiService.getLayers(
                    `where={"cam_ativa_consultaprevia":"true"}&orderBy=ASC:cam_ordem&`
                );

                const legendResponse = await getLayerLegendZoneamentoPalmeira();
                setAlvara(alvaraResponse);

                setProperty(propertyResponse);

                setPropertyGeom(propertyGeomResponse);

                setLayers(layersResponse.data);

                setAlllegends(legendResponse);

                // getLayerLegendZoneamentoPalmeira().then((res) => {
                //     if (!res.length) return;

                //     const legendId = showToast({
                //         type: "info",
                //         message: (
                //             <div className="text-sm text-slate-900 max-h-80 overflow-y-auto">
                //                 <p className="font-medium">
                //                     Legendas: Zoneamento
                //                 </p>
                //                 <div className="flex flex-row items-center flex-wrap overflow-y-auto">
                //                     {res.map(({ title, fill, stroke }) => (
                //                         <div className="flex flex-row items-center">
                //                             <span
                //                                 className="w-4 h-4 rounded ml-2 mr-2 p-0 text-slate-900"
                //                                 style={{
                //                                     backgroundColor: `${fill}`,
                //                                     borderWidth: "1px",
                //                                     borderColor:
                //                                         stroke || "black",
                //                                 }}
                //                             />
                //                             <span>{title || "Padrão"}</span>
                //                         </div>
                //                     ))}
                //                 </div>
                //             </div>
                //         ),
                //         options: {
                //             closeButton: true,
                //             autoClose: false,
                //             closeOnClick: false,
                //             position: "bottom-right",
                //             icon: false,
                //         },
                //     });

                //     setLegendToastId(legendId);
                // });
            }
        } finally {
            setLoading(false);
        }
    }, [setLoading, subscription]);

    useLayoutEffect(() => {
        fetchConsultaPrevia();
    }, [fetchConsultaPrevia]);

    return (
        <>
            <ConsultaPreviaStyle />
            <Container>
                {/* <HeaderPalmeira />
                <DisclaimerPalmeira />
                <ZoneamentoPalmeira
                    propertyInfo={property}
                    propertyGeom={propertyGeom}
                    layers={layers}
                    legends={allLegends}
                />
                <PalmeiraPrincipaisDados propertyInfo={property} />
                <PalmeiraAlvaras propertyInfo={property} />
                <PalmeiraLeiZoneamento propertyInfo={property} />
                <PalmeiraSolo />

                <ParkingLot />
                <PalmeiraGaragem /> */}
                <table>
                    <thead>
                        <tr>
                            <th>
                                <HeaderPalmeira />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <DisclaimerPalmeira />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ZoneamentoPalmeira
                                    propertyInfo={property}
                                    propertyGeom={propertyGeom}
                                    layers={layers}
                                    legends={allLegends}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <PalmeiraPrincipaisDados
                                    propertyInfo={property}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <PalmeiraAlvaras alvara={alvara} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <PalmeiraLeiZoneamento
                                    propertyInfo={property}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <PalmeiraSolo />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <PalmeiraLeiEstacionamento
                                    propertyInfo={property}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <PalmeiraGaragem />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="italic">
                                EMITIDO EM ►
                                {new Date(Date.now()).toLocaleDateString(
                                    "pt-BR"
                                )}
                            </td>
                        </tr>
                        {property && property.inscricaoimobiliaria && (
                            <tr className="italic">
                                <td>
                                    INSCRIÇÃO ► {property.inscricaoimobiliaria}
                                </td>
                            </tr>
                        )}
                    </tfoot>
                </table>
            </Container>
        </>
    );
};
