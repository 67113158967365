/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
    BsArrowLeftSquare,
    BsPlusSquare,
    BsPencilSquare,
    BsTrash,
} from "react-icons/bs";
import { FeaturesEnum } from "../../../../../../shared/enums/features.enum";
import { useAuth } from "../../../../../../shared/hooks/useAuth";
import { ICemeteryTaxesResponse } from "../../../../../../shared/providers/cemetery/entities/taxes-cemetery.interface";
import { CemeteryInfoApiService } from "../../../../../../shared/services/api/cemetery-api.service";
import { ApiErrorHandler } from "../../../../../../shared/utils/errors.utils";
import { Popup } from "../../../../../global/popup";
import { showToast } from "../../../../../global/toast";
import { AddTaxes } from "./add";
import { EditTaxes } from "./edit";

interface Props {
    doAfterSubmit(subscription: string): void;
    info?: Partial<ICemeteryTaxesResponse>;
    infoLayer?: Record<string, string>;
}

export const Taxes: React.FC<Props> = ({ doAfterSubmit, info, infoLayer }) => {
    const { user } = useAuth();
    const [enableCreate, setEnableCreate] = useState<boolean>(false);
    const [enableEdit, setEnableEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [popup, setPopup] = useState<{
        show: boolean;
        id: number | undefined;
    }>();

    const handleEnableEdit = () => {
        setEnableEdit(!enableEdit);
        setLoading(!loading);
    };
    const handleEnableCreate = () => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
    };

    const handleAfterCreateSubmit = (subscription: string) => {
        setEnableCreate(!enableCreate);
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };

    const handleAfterEditSubmit = (subscription: string) => {
        setEnableEdit(!enableEdit);
        setLoading(!loading);
        doAfterSubmit(subscription as string);
    };

    const handleDelete = async (id: number | undefined) => {
        if (id) {
            setPopup({
                show: true,
                id,
            });
        }
    };

    const handleDeleteCancel = () => {
        setPopup({
            show: false,
            id: undefined,
        });
    };

    const handleDeleteConfirm = useCallback(async () => {
        if (popup && popup.show && popup.id) {
            try {
                if (popup.id) {
                    setLoading(true);

                    await CemeteryInfoApiService.deleteTaxes(popup.id);

                    showToast({
                        message: `As informações das taxas foram deletadas com sucesso`,
                        type: "success",
                    });

                    doAfterSubmit(infoLayer?.inscricao as string);
                }
            } catch (err) {
                ApiErrorHandler(err, "warn");
            } finally {
                setLoading(false);
            }
        }
        setPopup({
            show: false,
            id: undefined,
        });
    }, [popup]);

    return (
        <>
            <div className="grid grid-cols-3 grid-rows-1">
                {!info || Object.keys(info).length === 0 ? (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {enableCreate
                                    ? "VOLTAR"
                                    : "ADICIONAR INFORMAÇÕES"}
                            </Tooltip>
                        }
                    >
                        <div className="mb-3 flex space-x-2 justify-center">
                            {user.authorizationsByFeatureName[
                                FeaturesEnum.CEMETERY_TAXES
                            ]?.canCreate && (
                                <button
                                    type="button"
                                    onClick={handleEnableCreate}
                                >
                                    {enableCreate ? (
                                        <BsArrowLeftSquare
                                            color="teal"
                                            size={20}
                                        />
                                    ) : (
                                        <BsPlusSquare color="teal" size={20} />
                                    )}
                                </button>
                            )}
                        </div>
                    </OverlayTrigger>
                ) : (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {enableEdit ? "VOLTAR" : "EDITAR INFORMAÇÕES"}
                            </Tooltip>
                        }
                    >
                        <div className="mb-3 flex space-x-2 justify-center">
                            {user.authorizationsByFeatureName[
                                FeaturesEnum.CEMETERY_TAXES
                            ]?.canUpdate && (
                                <button
                                    type="button"
                                    onClick={handleEnableEdit}
                                >
                                    {enableEdit ? (
                                        <BsArrowLeftSquare
                                            color="teal"
                                            size={20}
                                        />
                                    ) : (
                                        <BsPencilSquare
                                            color="teal"
                                            size={20}
                                        />
                                    )}
                                </button>
                            )}
                        </div>
                    </OverlayTrigger>
                )}
                {!enableCreate &&
                    !enableEdit &&
                    info &&
                    Object.keys(info).length !== 0 && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>APAGAR TAXAS</Tooltip>}
                        >
                            <div className="mb-3 flex space-x-2 justify-center">
                                {user.authorizationsByFeatureName[
                                    FeaturesEnum.CEMETERY_TAXES
                                ]?.canRemove && (
                                    <button
                                        type="button"
                                        onClick={() => handleDelete(info.id)}
                                    >
                                        <BsTrash color="red" size={20} />
                                    </button>
                                )}
                            </div>
                        </OverlayTrigger>
                    )}
            </div>
            {popup && popup.show && (
                <Popup
                    phrase="Tem certeza que deseja apagar as informações sobre taxas?"
                    show
                    handleConfirm={handleDeleteConfirm}
                    handleCancel={handleDeleteCancel}
                />
            )}

            {enableCreate && (
                <AddTaxes
                    subscription={infoLayer?.inscricao}
                    doAfterSubmit={handleAfterCreateSubmit}
                />
            )}
            {enableEdit && (
                <EditTaxes
                    taxesInfo={info}
                    doAfterSubmit={handleAfterEditSubmit}
                />
            )}
            {!enableCreate &&
                !enableEdit &&
                info &&
                Object.keys(info).length !== 0 && (
                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    TERRENO
                                </Form.Label>
                                <Form.Control value={info?.terreno} disabled />
                            </Col>
                            <Col sm={2}>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    SEPULTAMENTO
                                </Form.Label>
                                <Form.Control
                                    value={info?.sepultamento}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    AQUISIÇÃO DE JAZIGO
                                </Form.Label>
                                <Form.Control
                                    value={info?.construcao}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ fontWeight: "bold" }}>
                                    TRANSLADO
                                </Form.Label>
                                <Form.Control
                                    value={info?.translado}
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                )}
        </>
    );
};
