import React, { useEffect, useState } from "react";

import { BsPrinterFill, BsJournalCheck } from "react-icons/bs";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FeaturesEnum } from "../../../../../shared/enums/features.enum";
import { useAuth } from "../../../../../shared/hooks/useAuth";
import env from "../../../../../environments";
import { ICemeteryResponse } from "../../../../../shared/providers/cemetery/entities/cemetery.interface";

interface ActionsProps {
    cemetery: Partial<ICemeteryResponse>;
    doAfterClick?(): void;
    iconsSize?: number;
}

interface IUserAccess {
    modeloPosse: boolean;
    bcm: boolean;
}

export const CemiteryActions: React.FC<ActionsProps> = ({
    cemetery,
    doAfterClick,
    iconsSize = 18,
}) => {
    const { user } = useAuth();
    const [userAccess, setUserAccess] = useState<IUserAccess>(
        {} as IUserAccess
    );

    useEffect(() => {
        const allowedFeatures = user.authorizationsByFeatureName;
        setUserAccess({
            modeloPosse: allowedFeatures[FeaturesEnum.MODELO_POSSE]?.canRead,
            bcm: allowedFeatures[FeaturesEnum.BCM]?.canRead,
        });
    }, [user.authorizationsByFeatureName]);

    if (!userAccess) return null;

    return (
        <>
            {env.webgeo.config.bci && userAccess.bcm && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>BCM</Tooltip>}
                >
                    <a
                        href={`/bcm/${cemetery.inscricao}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={doAfterClick}
                    >
                        <BsPrinterFill color="teal" size={iconsSize} />
                    </a>
                </OverlayTrigger>
            )}

            {env.webgeo.config.consultaPrevia && userAccess.modeloPosse && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>MODELO DE POSSE</Tooltip>}
                >
                    <a
                        href={`/modelo-de-posse/${cemetery.inscricao}`}
                        target="_blank"
                        onClick={doAfterClick}
                        rel="noreferrer"
                    >
                        <BsJournalCheck color="teal" size={iconsSize} />
                    </a>
                </OverlayTrigger>
            )}
        </>
    );
};
