/* eslint-disable import/no-duplicates */
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";
import React from "react";

import { ICemeteryResponse } from "../../../../shared/providers/cemetery/entities/cemetery.interface";
import { ICemeteryDeceasedResponse } from "../../../../shared/providers/cemetery/entities/deceased-cemetery.interface";
import { ICemeteryTransferredResponse } from "../../../../shared/providers/cemetery/entities/transferred.interface";
// import { ICemeteryTransferredResponse } from "../../../../shared/providers/cemetery/entities/transferred.interface";

export interface TransladoEnvioProps {
    cemeteryInfo: ICemeteryResponse;
    deceasedSubscription: string | undefined;
}

export const TransladoEnvioBody: React.FC<TransladoEnvioProps> = ({
    cemeteryInfo,
    deceasedSubscription,
}) => {
    // const formattedData = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
    //     locale: ptBR,
    // });
    // const formattedDataShort = format(new Date(), "dd  MMMM yyyy", {
    //     locale: ptBR,
    // });

    // const options = { timeZone: "America/Sao_Paulo" };
    // const formattedTimeShort = new Date().toLocaleString("pt-BR", options);

    let formattedData = "";
    let formattedDataShort = "";
    // const formattedTimeShort = "";
    let currentDeceased = {} as ICemeteryDeceasedResponse | undefined;
    let transferred = {} as ICemeteryTransferredResponse | undefined;
    let idDeceased: number | undefined;

    if (cemeteryInfo && cemeteryInfo.deceased && cemeteryInfo.transferred) {
        currentDeceased = cemeteryInfo.deceased.find(
            (d) => d.inscricao_falecido === deceasedSubscription
        );
        idDeceased = currentDeceased?.id;
    }
    if (cemeteryInfo && cemeteryInfo.transferred && idDeceased) {
        transferred = cemeteryInfo.transferred.find(
            (d) => d.id_falecido === idDeceased && !d.foi_revertido
        );
        if (transferred?.data_atualizacao) {
            formattedData = format(
                new Date(transferred?.data_atualizacao),
                "dd 'de' MMMM 'de' yyyy",
                {
                    locale: ptBR,
                }
            );
            formattedDataShort = format(
                new Date(transferred?.data_atualizacao),
                "dd'/'MM'/'yyyy",
                {
                    locale: ptBR,
                }
            );
        }
    }

    return (
        <div>
            {currentDeceased?.translado_externo ? (
                <div>
                    <div className="w-full flex justify-center">
                        <h4 className="font-bold">
                            TRANSLADO DE RESTOS MORTAIS
                        </h4>
                    </div>
                    <br />
                    <div>
                        <p>
                            <strong>{cemeteryInfo.propnome}</strong>
                            &nbsp;, inscrito(a) no CPF sob o nº &nbsp;
                            <strong>{cemeteryInfo.propdocumento}</strong>,
                            portador(a) do RG:&nbsp;
                            <strong>{cemeteryInfo.proprg}</strong>, e
                            domiciliado(a) à&nbsp;
                            <strong>{cemeteryInfo.logradouronome}</strong>,{" "}
                            <strong>{cemeteryInfo.logradouronumero}</strong> -{" "}
                            <strong>{cemeteryInfo.bairronome}</strong>, no
                            município de&nbsp;
                            <strong>{cemeteryInfo.cidade}</strong>&nbsp;-&nbsp;
                            <strong>{cemeteryInfo.estado}</strong>.
                        </p>
                        Requer a realização da exumação dos restos mortais da
                        pessoa citada abaixo que será translado em conformidade
                        a legislação vigente dos cemitérios.
                    </div>
                    <br />
                    {transferred && (
                        <div>
                            <strong>
                                Exumação/Translado nº: {transferred.id}
                                <br />
                                Data Exumação/Translado: {formattedDataShort}
                                <br />
                                Sepultado: {transferred.nome} <br />
                                Data do Sepultamento:{" "}
                                {currentDeceased.data_sepultamento}
                                <br />
                                Origem: CEMITÉRIO MUNICIPAL SÃO BATISTA <br />
                                Quadra: {cemeteryInfo.grave?.quadra} <br />
                                Lote: {cemeteryInfo.grave?.lote} <br />
                                CONTROLE: {transferred.controle} <br />
                                Destino: {transferred.cemiterio_destino}
                                <br />
                                Quadra: {transferred.quadra} Lote:{" "}
                                {transferred.lote}
                                <br />
                                Município: {transferred.cidade} Estado:{" "}
                                {transferred.estado}
                            </strong>
                        </div>
                    )}
                    <br />
                    <div>
                        Comprometendo-se a recolher as taxas prevista por lei.
                        <br />
                        Nestes termos, peço o deferimento.
                    </div>
                    <div className="w-full flex justify-end">
                        <h5 className="font-bold">
                            Jacarezinho, {formattedData}.
                        </h5>
                    </div>
                    <br />
                    <div className="w-full flex justify-center">
                        <h5 className="font-bold">
                            ________________________________________________________________________
                        </h5>
                    </div>
                    <div className="w-full flex justify-center">
                        <strong>{cemeteryInfo.propnome}</strong>
                    </div>
                    <div className="w-full flex justify-center">
                        CPF: {cemeteryInfo.propdocumento} | RG:{" "}
                        {cemeteryInfo.proprg}
                    </div>
                    <div className="w-full flex justify-center">
                        <strong>PROPRIETÁRIO(A)</strong>
                    </div>
                    <br />
                    <br />
                    <div className="w-full flex justify-center">
                        <h5 className="font-bold">
                            ________________________________________________________________________
                        </h5>
                    </div>
                    <div className="w-full flex justify-center">
                        <strong>
                            Divisão de Administração do Cemitério Municipal São
                            João Batista
                        </strong>
                    </div>
                </div>
            ) : (
                <div>
                    ESTE FALECIDO NÃO POSSUI ESSE DOCUMENTO, POR FAVOR
                    VERIFIQUE.
                </div>
            )}
        </div>
    );
};
